// @todo Voir pour supprimer ce fichier

p {
  text-align: justify;
}

hr {
  margin-top: 25px;
}

section {
  .left-panel {
    padding-right: 10px;
  }
  .right-panel {
    padding-left: 10px;
  }
}

.result-actions {
  margin-top: 5px;
  margin-bottom: 20px;

  button {
    margin-bottom: 5px;
  }
}

.description {
  margin-top: 25px;
}

section {
  width: 100%;

  .diag-container {
    display: inline-block;
    height: 400px;
    width: 300px;
  }
}

@media print {
  .img-panel {
    height: 300px;
  }
}
