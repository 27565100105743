@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "app/old_components/dossier-details/dossier-details";
@import "tailwind";
@import "variables";

body {
  margin: 0;
  font-family: Roboto, sans-serif;
}

h2 {
  color: $noty-green;
}

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.mat-card-header-text {
  width: 100%;
}

.mat-dialog-container {
  background-color: white;
}

mat-toolbar {
  justify-content: space-between;
}

a[color=primary],
button[color=primary], {
  color: white !important;
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px #00000024, 0 1px 5px #0000001f;
  height: 38px;
}

.text-strong {
  font-weight: bold;
}

.pull-right {
  float: right;
}

.text-right {
  text-align: right;
}

.ellipsis {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.only-print {
  display: none;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }

  .only-print {
    display: block;
  }
}
