// Supprimer quand TailwindCSS sera mis en place

.absolute {
  position: absolute;
}

.align-center {
  align-items: center;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-cover {
  background-size: cover;
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.bg-white {
  background-color: white;
}

.border-0 {
  border-width: 0;
}

.border {
  border-width: 1px;
}

.border-dotted {
  border-style: dotted;
}

.border-solid {
  border-style: solid;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity))
}

.border-t {
  border-top-width: 1px;
}

.divide-gray-200 {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}

.divide-Y {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.flex {
  display: flex;
}

.font-bold {
  font-weight: 700;
}

.gap-12 {
  gap: 3rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.h-20 {
  height: 5rem;
}

.h-48 {
  height: 12rem;
}

.h-56 {
  height: 14rem;
}

.h-44 {
  height: 11rem;
}

.h-60 {
  height: 15rem;
}

.h-full {
  height: 100%;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.m-0 {
  margin: 0;
}

.m-auto {
  margin: auto;
}

.max-h-full {
  max-height: 100%;
}

.max-w-2\.5xl {
  max-width: 44rem;
}

.max-w-3\.5xl {
  max-width: 54rem;
}

.max-w-10xl {
  max-width: 104rem;
}

.max-w-full {
  max-width: 100%;
}

.mb-4 {
  margin-bottom: 1rem;
}

.min-h-screen {
  min-height: 100vh;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-24 {
  padding-top: 6rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.relative {
  position: relative;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.transform.-rotate-25 {
  --tw-rotate: -25deg;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-center {
  text-align: center;
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-left {
  text-align: left;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.-top-10 {
  top: -2.5rem;
}

.top-1\/2 {
  top: 50%;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.w-20 {
  width: 5rem;
}

.w-1\/2 {
  width: 50%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-full {
  width: 100%;
}

.z-10 {
  z-index: 10;
}

@media (min-width: 640px) {
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:h-60 {
    height: 15rem;
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-4\/5 {
    width: 80%;
  }
}

@media (min-width: 1024px) {
  .lg\:gap-6 {
    gap: 1.5rem;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:max-w-10xl {
    max-width: 104rem;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }
}

@media (min-width: 1280px) {
  .xl\:mt-12 {
    margin-top: 3rem;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }
}
